import { createTheme } from '@mui/material/styles';

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#283593', // Deep blue
    },
    secondary: {
      main: '#66bb6a', // Muted green
    },
    background: {
      default: '#f5f5f5', // Light grey
      paper: '#fff',
    },
    link: {
      main: '#1e88e5', // Slightly brighter blue for links
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#546e7a', // Slate blue
    },
    secondary: {
      main: '#ce93d8', // Dusty pink
    },
    background: {
      default: '#212121', // Very dark grey
      paper: '#424242', // Dark grey for paper elements
    },
    link: {
      main: '#FFFFFF', // Brighter cyan for links, providing better contrast
    },
  },
});
