import React from 'react';
import Social from '../social/Social';
import { Box, Typography } from '@mui/material';

function Footer() {
  const year = new Date().getFullYear();

  return (
    <Box sx={{
      position: 'static',
      bottom: 0,
      width: '100%',
      padding: '50px',
      textAlign: 'center'
    }}>
      <Box sx={{ mb: 2 }}>
        <Social />
      </Box>
      <Typography variant="caption" component="p">
        Copyright {year}. Rojan Thomas. All rights reserved.
      </Typography>
    </Box>
  );
}

export default Footer;
