import React from 'react';
import Layout from '../../components/Layout';
import PortfolioItem from '../../components/portfolio/PortfolioItem';
import { Box, Typography, Link as MuiLink } from '@mui/material';

function Portfolio() {
    const dateStarted = new Date('2015-08-10');
    const dateNow = new Date();
    const yearsExperience = getYearsOfExperience(dateStarted, dateNow);

    return (
        <Layout>
            <Box sx={{ padding: 2 }}>
                <Typography variant="body1" gutterBottom sx={{ mb: 8 }}>
                    I have {yearsExperience} years of professional experience working on various Android apps on the Play Store. Additionally, I've worked on Android apps used internally by other colleagues to facilitate business operations.
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, alignItems: 'center' }}>
                    <MuiLink href="https://play.google.com/store/apps/details?id=com.tesco.grocery.view" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none', color: 'inherit', width: '100%', maxWidth: 500 }}>
                        <PortfolioItem image="./portfolio_image_tesco.webp" title="Tesco Grocery & Clubcard" desc="Tesco clubcard & shopping app." />
                    </MuiLink>
                    <MuiLink href="https://play.google.com/store/apps/details?id=com.ctm.cinema" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none', color: 'inherit', width: '100%', maxWidth: 500 }}>
                        <PortfolioItem image="./portfolio_image_meerkat.webp" title="comparethemarket.com" desc="Compare prices & use your Meerkat Rewards." />
                    </MuiLink>
                    <MuiLink href="https://play.google.com/store/apps/details?id=uk.co.club.costa.costa" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none', color: 'inherit', width: '100%', maxWidth: 500 }}>
                        <PortfolioItem image="./portfolio_image_costa.webp" title="Costa Coffee Club" desc="Costa Coffee Club loyalty app to collect beans and order your favourite drink." />
                    </MuiLink>
                    <MuiLink href="https://play.google.com/store/apps/details?id=com.homeretailgroup.argos.android" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: 'none', color: 'inherit', width: '100%', maxWidth: 500 }}>
                        <PortfolioItem image="./portfolio_image_argos.webp" title="Sainsbury's Argos" desc="Browse thousands of products to buy with click & collect or delivery." />
                    </MuiLink>
                </Box>
            </Box>
        </Layout>
    );
}

function getYearsOfExperience(dateFrom, dateTo) {
    return Math.round(
        (dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))) / 12
    );
}

export default Portfolio;
