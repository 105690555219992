import Layout from '../../components/Layout';

import './Blog.css'

function Blog() {
  return (
    <div>
      <Layout>
        <div>
          <p>Blog</p>
        </div>
      </Layout>
    </div>
  );
}

export default Blog;
