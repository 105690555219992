import React from 'react';
import { Box, Typography } from '@mui/material';

function PortfolioItem(props) {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'background.paper',
      padding: '10px',
      borderRadius: '4px',
      boxShadow: 1,
      '&:hover': {
        boxShadow: 3,
      },
      width: '100%'
    }}>
      <Box component="img" 
           src={props.image} 
           alt={`${props.title} logo`} 
           sx={{ width: 60, height: 60, mr: 2, borderRadius: '50%' }}
      />

      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
          {props.title}
        </Typography>
        <Typography variant="body">
          {props.desc}
        </Typography>
      </Box>
    </Box>
  );
}

export default PortfolioItem;
