import Box from '@mui/material/Box'; // Importing Box from MUI

const Layout = ({ children }) => {
  return (
    <Box sx={{ margin: '0 auto', maxWidth: 960, padding: 2, textAlign: 'left' }}>
      {children}
    </Box>
  );
};

export default Layout;
