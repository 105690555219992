import './App.css';
import { Routes, Route } from "react-router-dom"
import { useState, useEffect } from 'react';

import Home from './pages/home/Home';
import Blog from './pages/blog/Blog';
import Portfolio from './pages/portfolio/Portfolio';

import { ThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from './Theme';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import { CssBaseline, Box } from '@mui/material';

function App() {
  const [themeMode, setThemeMode] = useState('light');

  useEffect(() => {
    const savedTheme = localStorage.getItem('themeMode') || 'dark';
    setThemeMode(savedTheme);
  }, []);

  const toggleTheme = () => {
    const newTheme = themeMode === 'light' ? 'dark' : 'light';
    setThemeMode(newTheme);
    localStorage.setItem('themeMode', newTheme);
  };

  return (
    <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
      <CssBaseline />

      <div className="App">
        <Header themeMode={themeMode} toggleTheme={toggleTheme} />
        <Box sx={{ bgcolor: 'background.default' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/portfolio" element={<Portfolio />} />
          </Routes>
        </Box>
        <Footer />
      </div>

    </ThemeProvider>
  );
}

export default App;
