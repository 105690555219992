import { AppBar, Toolbar, IconButton, Typography, Stack, Button } from '@mui/material';
import Android from '@mui/icons-material/Android'
import LightMode from '@mui/icons-material/LightMode'
import DarkMode from '@mui/icons-material/DarkMode'

import { Link } from "react-router-dom";

function Header({ themeMode, toggleTheme }) {
  return (
    <AppBar position="static" sx={{ marginBottom: 16 }}>
      <Toolbar>
        <IconButton size='large' edge='start' color='inherit' component={Link} to='/' aria-label="logo">
          <Android />
        </IconButton>
        <Typography variant='button' color='inherit' component={Link} to='/' align='left' sx={{ flexGrow: 1, textDecoration: 'none' }}>
          ROJAN THOMAS
        </Typography>
        <Stack direction="row" spacing={2}>
          {
            process.env.NODE_ENV === 'development' &&
            <Button color='inherit' component={Link} to='/blog'>
              Blog
            </Button>
          }
          <Button color='inherit' component={Link} to='/portfolio'>
            Portfolio
          </Button>
          <IconButton size='large' edge='start' color='inherit' onClick={() => { toggleTheme() }}>
            {themeMode === 'light' ? <DarkMode /> : <LightMode />}
          </IconButton>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default Header;
