import { Box } from '@mui/material';
import { SocialIcon } from 'react-social-icons';

function Social() {
  return (
    <Box display="flex" justifyContent="center">
      <SocialIcon
        component={Box}
        url="https://www.linkedin.com/in/rojan-thomas/"
        style={{ height: 30, width: 30, marginRight: '4px' }} // Added marginRight
        fgColor="#FFFFFF"
      />
      <SocialIcon
        component={Box}
        url="https://medium.com/@rojanthomas"
        style={{ height: 30, width: 30, margin: '0 4px' }} // Added margin to both sides
        fgColor="#FFFFFF"
      />
      <SocialIcon
        component={Box}
        url="https://github.com/rojanthomas"
        style={{ height: 30, width: 30, margin: '0 4px' }} // Added margin to both sides
        fgColor="#FFFFFF"
      />
      <SocialIcon
        component={Box}
        url="https://www.instagram.com/rojansthomas/"
        style={{ height: 30, width: 30, marginLeft: '4px' }} // Added marginLeft
        fgColor="#FFFFFF"
      />
    </Box>
  )
}

export default Social;
