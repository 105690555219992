import { Box } from '@mui/material';
import Layout from '../../components/Layout';
import Link from '@mui/material/Link';

function Home() {
  return (
    <div>
      <Layout>
        <Box mb={8} display="flex" justifyContent="center" alignItems="center">
          <Box  component='img' sx={{ width: 200, borderRadius: 64 }} src='profile.jpg' />
        </Box>

        Hi! I'm Rojan Thomas, a Senior Software Engineer (Android) at

        {' '}
        <Link href="https://www.comparethemarket.com/" target="_blank" rel="noopener noreferrer">
          comparethemarket.com
        </Link>
        {`. I have over 10 years of experience working with Android and software development.`}
        <br/>
        <br/>
        {`I'm passionate about all things technology and love to learn new things. I also like working on cars and fixing them up.`}
      </Layout>
    </div>
  );
}

export default Home;
